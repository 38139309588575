<template>
  <div class="excel-container" ref="mySpread"></div>
</template>

<script>
export default {
  name: 'ExcelPreview',
  props: {
    excelFile: {
      type: Blob,
      default: null
    }
  },
  methods: {
    loadExcel () {
      this.mySpread = new GC.Spread.Sheets.Workbook(this.$refs.mySpread, { sheetCount: 0 })
      const excelIo = new GC.Spread.Excel.IO()
      excelIo.open(this.excelFile, json => {
        this.mySpread.fromJSON(json)
      })
    }
  },
  mounted () {
    this.loadExcel()
  }
}
</script>

<style scoped lang="scss">
.excel-container {
  width: 100%;
  height: 600px;
}
</style>
